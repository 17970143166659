import React from 'react';
import PageTransition from "../utils/Transitions/transition";

const NotFound = () => {
  return (
    <PageTransition className="not-found">
      <h1>404</h1>
    </PageTransition>
  )
}

export default NotFound;